import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import {
  getUserFromCookie, getTokenCookie, setCookies, removeCookie,
} from '@Helpers/authUtils';
import { toast } from 'react-toastify';

export default function Hook() {
  const tokenCookie = getTokenCookie();
  const userCookie = getUserFromCookie();
  const [token, setToken] = useState(tokenCookie);
  const [user, setUser] = useState(userCookie);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [isMap, setIsMap] = useState(false);
  const [openDemoTour, setOpenDemoTour] = useState(false);
  const [isDemoHidden, setIsDemoHidden] = useState(false);

  const login = (code) => {
    setIsLoading(true);
    setLoginError('');
    Api({
      endpoint: endpoints.getAccessToken(code),
      onSuccess: (response) => {
        console.log('🚀 vv ~ getAccessToken', response);
        setToken(response.access_token);
        setCookies('token', response.access_token);
        setIsLoading(false);
        window.location = '/ticket';
      },
      onFail: (err) => {
        setToken();
        removeCookie();
        setIsLoading(false);
        setLoginError(err);
        toast('getAccessToken error', err);
      },
    });
  };

  const loginWithClickup = () => {
    window.location.href = `https://app.clickup.com/api?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
  };

  return {
    login,
    loginError,
    token,
    user,
    setUser,
    layers: [],
    isLoading,
    setIsLoading,
    setLoginError,
    isMap,
    setIsMap,
    openDemoTour,
    setOpenDemoTour,
    isDemoHidden,
    setIsDemoHidden,
    loginWithClickup,
  };
}
