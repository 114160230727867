import React from 'react';
import { Grid, Avatar, IconButton } from '@material-ui/core';
import { NotificationsOutlined, ExitToApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import MainLogo from '@Assets/Icons/Logo.svg';

export default function index() {
  const projectSiteStyle = {
    zIndex: 99,
    backgroundColor: '#1c1c23',
    borderBottom: '1px solid #3D3737'
  };

  return (
    <Grid container item xs={12} direction="row" justify="space-between" alignItems="center" className="topbar navbar-text" style={{ ...projectSiteStyle }}>
      <Link to="/">
        <img src={MainLogo} height={25} />
      </Link>
      <div className="d-flex align-items-center">
        <IconButton>
          <NotificationsOutlined style={{ fill: 'white' }} />
        </IconButton>
        <Avatar style={{ height: 20, width: 20, cursor: 'pointer' }} variant="rounded" />
        <IconButton>
          <ExitToApp style={{ fill: '#e76560' }} />
        </IconButton>
      </div>
    </Grid>
  );
}
