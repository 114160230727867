import {
  Button, Grid, TextField, InputAdornment, IconButton,
} from '@material-ui/core';
import {
  ArrowBack, ArrowForward, Visibility, VisibilityOff,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: '#292830',
  },
  label: {
    marginTop: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'grey',
  },
}));

export default function LoginForm(h) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Grid item container xs={12} md={12} style={{ backgroundColor: '#1c1c23', padding: 40, borderRadius: 10, height: '100%' }}>
      <Grid item container xs={12}>
        <p style={{ color: 'white', fontSize: 20, fontWeight: 600 }}>Login to your account</p>
      </Grid>
      <Grid item container xs={12}>
        <p style={{ color: 'grey', marginTop: 40, marginBottom: 10 }}>Username</p>
        <TextField
          onKeyDown={h.onKeyDown}
          id="standard-basic"
          placeholder="Enter your email address here"
          variant="outlined"
          InputProps={{ className: classes.input }}
          fullWidth
          value={h.email}
          onChange={(e) => h.setEmail(e.target.value)}
        />
      </Grid>
      <Grid item container xs={12}>
        <p style={{ color: 'grey', marginTop: 40, marginBottom: 10 }}>Password</p>
        <TextField
          onKeyDown={h.onKeyDown}
          id="standard-password-input"
          variant="outlined"
          placeholder="Enter your password here"
          type={showPassword ? 'text' : 'password'}
          value={h.password}
          onChange={(e) => h.setPassword(e.target.value)}
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff style={{ color: 'white' }} /> : <Visibility style={{ color: 'white' }} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete="current-password"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', marginTop: 10, marginBottom: 80 }}>
        <h6
          style={{ color: '#6082c2', fontSize: 12 }}
        >
          Forgot your Password?
        </h6>
        <h6
          onClick={h.toggleForgotPassword}
          style={{ color: '#c15654', fontSize: 12, cursor: 'pointer' }}
        >
          &nbsp;Click Here
        </h6>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => h.attemptLoginOrPasswordReset()}
          className="mx-auto w-100"
          style={{
            backgroundColor: '#3272c3', borderRadius: '5px', borderColor: 'none',
          }}
        >
          <p className="text-white">{h.isForgotPassword ? 'RESET PASSWORD' : 'LOG IN'}</p>
        </Button>
      </Grid>
      <Grid item xs={10}>
        <div className="pt-2 d-flex justify-content-center" style={{ color: 'red', fontSize: 12, fontWeight: 700 }}>
          {h.loginError === 'fail to authenticate user' ? 'Invalid username or password' : h.loginError}
        </div>
      </Grid>
    </Grid>
  );
}
