import {
  IconButton, InputAdornment, MenuItem, TextField,
} from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

const SearchTextField = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '5px',
    paddingLeft: 100,
  },
  backgroundColor: '#292830',
  borderRadius: '5px',
  color: 'white',
  width: '30vw',
}));

const useStyles = makeStyles({
  input: { color: 'white', backgroundColor: '#44444d', width: '10vw', marginLeft: -30 },
});

export default function index({
  onChange, onKeyDown = () => null, onClick = () => null, hasOnClick = false, label = 'Search here...', dropdownValue, setDropdownValue
}) {
  const SearchIcon = () => (
    hasOnClick
      ? <IconButton onClick={onClick} style={{ color: 'white' }}><Search /></IconButton>
      : <IconButton style={{ pointerEvents: 'none', color: 'white' }}><Search /></IconButton>
  );
  const classes = useStyles()
  return (
    <SearchTextField
      size="small"
      variant="outlined"
      onChange={onChange}
      placeholder="Search here..."
      onKeyDown={onKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment>
            <TextField
              select
              variant="outlined"
              size="small"
              style={{ transform: 'scale(0.8)' }}
              placeholder="All Categories"
              InputProps={{ className: classes.input }}
              value={dropdownValue}
              onChange={(e) => setDropdownValue(e.target.value)}
            >
              <MenuItem value={0} key={0}>All Categories</MenuItem>
              <MenuItem value={1} key={1}>a</MenuItem>
              <MenuItem value={2} key={2}>b</MenuItem>
              <MenuItem value={3} key={3}>c</MenuItem>
            </TextField>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment>
            <div style={{
              backgroundColor: '#3b73f6', borderRadius: 10, marginRight: -19, transform: 'scale(0.7)',
            }}
            >
              <SearchIcon />
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}
