import { Container, Grid } from '@material-ui/core';
import landingImage from '@Assets/Icons/OBJECTS.svg';
import logo from '@Assets/Icons/Logo.svg';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import useHook from './hook'

export default function LandingPageHelpdesk() {
  const h = useHook();
  return (
    <>
      <Grid container xs={12} md={12} style={{ height: '100vh', width: '100vw', backgroundColor: '#131319' }}>
        <Grid item container xs={12} md={12} style={{ height: '10vh' }}>
          <div className="d-flex align-items-center p-4">
            <img src={logo} style={{ width: 40 }}/>
            <h1 style={{ fontWeight: 600, color: 'white', fontSize: 20 }}>&nbsp;&nbsp;HELP DESK</h1>
          </div>
        </Grid>
        <Grid item container xs={12} md={12}>
          <Grid item xs={6} md={6}>
            <div className="text-center mx-auto">
              <img
                src={landingImage}
                className="img-fluid mb-4 pb-0"
                style={{ width: '80%' }}
              />
            </div>
          </Grid>
          <Grid item xs={6} md={6} style={{ paddingInline: 80, paddingBottom: 80 }}>
            <LoginForm {...h} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
