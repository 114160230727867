/* eslint-disable max-len */
export const URL = process.env.REACT_APP_ENDPOINT_URL;
export const CLICKUP = process.env.REACT_APP_CLICKUP_ENDPOINT;

export const endpoints = {
  getAccessToken: (code) => ['POST', `${CLICKUP}oauth/token?client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&code=${code}`],
  getTeams: () => ['GET', `${CLICKUP}team`],
  getSpaces: (team_id) => ['GET', `${CLICKUP}team/${team_id}/space`],
  getFolders: (space_id) => ['GET', `${CLICKUP}space/${space_id}/folder`],
  createTask: (list_id) => ['POST', `${CLICKUP}list/${list_id}/task`],
  createTaskAttachment: (task_id) => ['POST', `${CLICKUP}task/${task_id}/attachment`],
};
