import React, { useState, useEffect } from 'react'

export default function hook() {
  const [dropdownValue, setDropdownValue] = useState(0)

  return {
    dropdownValue,
    setDropdownValue,
  };
}
