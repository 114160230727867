/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

export default (props) => {
  const { plan, mode } = queryString.parse(window.location.search);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState();
  const [cardExpDate, setCardExpDate] = useState('');
  const [cvc, setCvc] = useState();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isSuccessCreated, setIsSuccessCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [loginAuth, setLoginAuth] = useState({ email: null, password: null });
  const [isTaken, setIsTaken] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const history = useHistory();

  return {
    plan,
    mode,
    selectedProduct,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    company,
    setCompany,
    email,
    setEmail,
    password,
    setPassword,
    cardName,
    setCardName,
    cardNumber,
    setCardNumber,
    cardExpDate,
    setCardExpDate,
    cvc,
    setCvc,
    openModal,
    setOpenModal,
    handleOpenModal,
    handleCloseModal,
    passwordVisibility,
    setPasswordVisibility,
    isSuccessCreated,
    loginAuth,
    isLoading,
    isProductLoading,
    isTaken,
    setIsTaken,
  };
};
