import React, { useState } from 'react';
import {
  Card, Grid, Typography, CardActionArea, CardMedia, CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles({
  root: { maxWidth: '100%', margin: '0 2px' },
  media: { height: '18vh', objectFit: 'contain', padding: 20 },
  title: { fontWeight: 'bold', fontFamily: 'CeraProRegular', color: 'white', fontSize: 14 },
});


export default function AssetCard({
  title = 'TITLE', image, pathname = '', description
}) {
  const classes = useStyles();
  const [onHover, setOnHover] = useState(false);
  return (
    <Grid item xs={6} sm={4} md={3} lg={3} className="mt-2">
      <Card
        onMouseOver={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        className={classes.root}
        style={{ transform: onHover ? 'scale(1.02)' : 'scale(1)', transition: 'all .5s', backgroundColor: '#1c1c23', outline: '1px solid #3D3737', height: '35vh' }}
      >
        <CardActionArea>
          <Link to={{ pathname }} target="_blank">
            <CardMedia className={classes.media} image={image} />
            <CardContent className="position-relative">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className={classes.title}>{title}</p>
                <ChevronRight style={{ color: 'white', width: 16 }}/>
              </div>
              <p style={{ color: 'grey', fontSize: 12 }}>{description}</p>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
