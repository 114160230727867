import React, { useContext } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { Grid } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logout from '@Components/Logout';
import { AuthProvider, AuthContext } from '@Context/Auth';
import PrivacyPolicy from '@Pages/PrivacyPolicy';
import DeniedPage from '@Pages/DeniedPage';
import Home from '@Pages/Home';
import TopBar from '@Components/TopBar';
import Login from '@Pages/LandingPageHelpdesk'
import './App.css';
import Hook from './hook';

export default function App() {
  const h = Hook();
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/logout">
            <Logout {...h} />
          </Route>
          <Route exact path="/login">
            <Login {...h} />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/denied">
            <DeniedPage />
          </Route>
          <Route exact path="/">
            <MainContainer user={h.user} isHome child={<Home {...h} />} />
          </Route>
          {/* <PrivateRoute exact path="/ticket">
            <Ticket {...h} />
          </PrivateRoute> */}
          <UndeclareRoute />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

function PrivateRoute({ children, accessible = true, ...rest }) {
  const Auth = useContext(AuthContext);
  if (!accessible && !!rest.user) return <Redirect to="/" />;
  return (
    <Route
      {...rest}
      render={({ location }) => (Auth.token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

function MainContainer({
  user, child, adjustedStyle, isProjectSite = false, isHome
}) {
  return (
    <Grid
      className="content"
      style={{
        position: 'fixed',
        paddingTop: isProjectSite && '70px',
        top: isProjectSite ? '0px' : '60px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        overflow: 'auto',
        ...adjustedStyle,
        background: isHome ? 'linear-gradient(180deg, #1c1c23 40%, #131319 0%)' : '#131319',
      }}
    >
      <TopBar user={user} />
      <Grid item xs={12}>
        {child}
      </Grid>
    </Grid>
  );
}

function UndeclareRoute() {
  return (
    <Route path="*" render={() => (<Redirect to={{ pathname: '/denied' }} />)} />
  );
}
