import { Grid, Container } from '@material-ui/core';
import SearchBox from '@Components/SearchBox';
import CardsList from './CardsList';
import useHook from './hook';

export default () => {
  const h = useHook();
  return (
    <div>
      <Container maxWidth="lg">
        <h1 style={{ fontWeight: 600, color: 'white', textAlign: 'center', marginTop: 20 }}>Welcome, OFO Dev!</h1>
        <Grid xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
          <SearchBox {...h} />
        </Grid>
        <Grid xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ color: 'grey' }}>Suggestion: </p>
            {[
              {
                label: 'ISR',
                value: 'isr',
              },
              {
                label: 'Tutorial',
                value: 'tutorial',
              },
              {
                label: 'HTML5',
                value: 'html',
              },
              {
                label: 'CSS 3',
                value: 'css',
              },
              {
                label: 'Analytic',
                value: 'analytic',
              },
            ].map(d => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 5,
                  backgroundColor: '#292830',
                  padding: 5,
                  marginLeft: 10,
                  height: 10,
                }}
              >
                <p style={{ fontSize: 14, color: 'grey' }}>{d.label}</p>
              </div>
            ))}
          </div>
        </Grid>
        <Grid container spacing={1}>
          <CardsList {...h} />
        </Grid>
      </Container>
    </div>
  );
};
