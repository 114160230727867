import React from 'react';
import Card from '@Components/Card';
import ManageProject from '@Assets/Images/ManageProject.svg'
import ISR from '@Assets/Images/ISR.svg'
import ProjectAnalysis from '@Assets/Images/ProjectAnalysis.svg'
import AdHoc from '@Assets/Images/AdHoc.svg'
import AddOn from '@Assets/Images/AddOn.svg'
import Api from '@Assets/Images/Api.svg'
import FAQ from '@Assets/Images/FAQ.svg'
import { Grid } from '@material-ui/core';

export default function CardsList() {
  return (
    <Grid container xs={12} md={12} spacing={2}>
      {[
        {
          title: 'Manage Project',
          image: ManageProject,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'Manage and track your project with teammate.',
        },
        {
          title: 'Internal Service Request',
          image: ISR,
          pathname: 'https://master.d2vkhk9i8il7sf.amplifyapp.com/',
          description: 'Raise an internal service request by filling a form.',
        },
        {
          title: 'Project Analysis',
          image: ProjectAnalysis,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'Get an overview regarding your project analysis and performance.',
        },
        {
          title: 'Ad Hoc Task',
          image: AdHoc,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'Request an urgent ad hoc task by filling a form.',
        },
        {
          title: 'Add Ons',
          image: AddOn,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'Manage and track your other minor tasks.',
        },
        {
          title: 'A P I',
          image: Api,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'View and track your API performances.',
        },
        {
          title: 'FAQ',
          image: FAQ,
          pathname: 'https://master.d3vkiff6849kky.amplifyapp.com/',
          description: 'View and read a list of frequently asked questions.',
        },
      ].map((props) => <Card {...props} />)}
    </Grid>
  );
}
